<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.indexes') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.indexes') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /><span>{{ $t('newIndex') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="name" :label="$t('name')" width="20%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="enabled" :label="$t('status')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value" class="tag is-success is-light">{{ $t('enabled') }}</span>
            <span v-else class="tag is-warning is-light">{{ $t('disabled') }}</span>
          </template>
        </data-column>
        <data-column field="max_size" :label="$t('maxSize')" width="10%">
          <template slot-scope="{ value, item: index }">
            <span class="is-uppercase">{{ value }}</span><span class="is-uppercase">{{ index.unit }}</span>
          </template>
        </data-column>
        <data-column field="rotate_time" :label="$t('rotateTime')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value || value != 0">{{ value }} Days</span>
            <span v-else>Unlimited</span>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.name || $t('newIndex') }}
        </div>

        <form @submit.prevent="submit">
          <div class="field">
            <label class="label">{{ $t('name') }}</label>
            <div class="control">
              <input
                v-model="item.name" class="input" type="text"
                required :disabled="isedit" style="text-transform: lowercase"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('description') }}</label>
            <div class="control">
              <input v-model="item.description" class="input" type="text">
            </div>
          </div>

          <div style="width: 50%; float: left;">
            <label class="label">{{ $t('maxSize') }}</label>
            <div class="field has-addons">
              <p class="control">
                <input
                  v-model="item.max_size" class="input" type="number"
                  required title="Rotate the log when the index exceeds this capacity"
                >
              </p>
              <p class="control">
                <span class="select">
                  <select v-model="item.unit" required>
                    <option disabled value="">{{ $t('unit') }}</option>
                    <option v-for="unit in units" :key="unit" :value="unit">{{ unit.toUpperCase() }}</option>
                  </select>
                </span>
              </p>
            </div>
          </div>

          <div style="width: 50%; float: right;">
            <label class="label">{{ $t('Rotate Time') }}</label>
            <div class="field has-addons">
              <p class="control">
                <input
                  v-model="item.rotate_time" class="input" type="number"
                  required title="0 value as unlimited"
                >
              </p>
              <p class="control">
                <span class="select">
                  <select disabled required>
                    <option selected="true">Days</option>
                  </select>
                </span>
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('status') }}</label>
            <input
              id="enabled" v-model="item.enabled" type="checkbox"
              name="enabled" class="switch"
            >
            <label for="enabled">{{ item.enabled ? $t('enabled') : $t('disabled') }}</label>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal } from '@cyradar/ui'
import { plus, x, pencil } from 'octicons-vue'

const units = ['gb', 'tb', 'mb']

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      modal: false,
      isedit: true,
      item: null
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    units () {
      return units
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.isedit = false
      this.item = {
        unit: 'gb',
        max_size: 10,
        rotate_time: 90,
        enabled: true
      }

      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.isedit = true
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/indexes', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      this.item.max_size = parseFloat(this.item.max_size)
      this.item.rotate_time = parseFloat(this.item.rotate_time)
      this.item.name = this.item.name.toLowerCase()
      var pattern = /^[a-z0-9\\-]+$/
      if (!pattern.test(this.item.name)) {
        return this.$store.dispatch('NOTIFY', {
          type: 'warning',
          error: {
            message: 'Invalid Index Name (Accept: alphabet, number, -)'
          }
        })
      }
      return this.$http.post('/api/v1/indexes', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      this.item.max_size = parseFloat(this.item.max_size)
      this.item.rotate_time = parseFloat(this.item.rotate_time)
      return this.$http.patch(`/api/v1/indexes/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/indexes/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
